import axios from 'axios';
import https from 'https';
import userData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/userData';
import windowManipulationService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/windowManipulationService';

const isClientSideRendering = windowManipulationService.isBrowserEnvironment();
const queryParams = {};
if (isClientSideRendering && ADRIATIC_PARTNER_RESPONSIVE_SCHEME_HOST === `${window.location.protocol}//${window.location.hostname}`) {
    const params = new URLSearchParams(window.location.search);
    const fsSet = params.get('fs_set');
    if (fsSet) {
        queryParams.fs_set = fsSet;
    }
}
const apiHostname = isClientSideRendering ? window.location.hostname : ADRIATIC_RESPONSIVE_SCHEME_HOST.replace('https://', '');
const BASE_URL = `https://${apiHostname}/`;
const RSB_SERVICE = `${BASE_URL}rsb-service/`;
const agent = new https.Agent({
    rejectUnauthorized: isClientSideRendering || false,
});
const axiosInstance = axios.create({
    timeout: 40000,
    httpsAgent: agent,
    // headers: { 'Content-Type': 'application/json' }, // Ovo ne zbog uploada fajlova
    params: queryParams,
});

export default class serviceData {
    static getCurrentJobId() {
        return isClientSideRendering && window.PageConfig ? window.PageConfig.jobVacancyId : 0;
    }

    static getAdriaticLang() {
        // TODO: #55289 Postavljen je defaultni jezik na "en" iako se izravno kroz site ne moze desit da jezik nije postavljen,
        // plan mi je ukloniti ovo nakon testiranja na produkciji
        const defaultLang = 'en';
        return isClientSideRendering && window.AppConfig ? window.AppConfig.lang : defaultLang;
    }

    static twoLetterISOLanguageCodeParameter = this.getAdriaticLang();

    static setupInterceptors() {
        axiosInstance.interceptors.request.use(
            async (config) => {
                // TODO: #55289 Nakon testiranja prvog page-a će se dodati logika za SSR sa odgovarajućim tokenom.
                if (isClientSideRendering) {
                    const token = await userData.getTokenFromLocalStorage();
                    config.headers['Adriatic-Bearer'] = token || 0;
                } else {
                    config.headers['Adriatic-Bearer'] = 0;
                }
                return config;
            },
            (error) => Promise.reject(error),
        );

        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => Promise.reject(error),
        );
    }

    // Dodatni parametar langFromSSR koji dolazi preko symfony-a (ne šalje se prilikom poziva sa CSR-a).
    static getData(endpointPath, urlParam, langFromSSR) {
        const completePath = `${RSB_SERVICE}${endpointPath}${langFromSSR || this.twoLetterISOLanguageCodeParameter}`;
        if (urlParam) {
            return axiosInstance.get(completePath + urlParam);
        }
        return axiosInstance.get(completePath);
    }

    static getDataWithoutLangISO(endpointPath, urlParam) {
        const completePath = `${RSB_SERVICE}${endpointPath}`;
        if (urlParam) {
            return axiosInstance.get(completePath + urlParam);
        }
        return axiosInstance.get(completePath);
    }

    static getDataTwoLetterBefore(endpointPath) {
        const completePath = `${RSB_SERVICE}${this.twoLetterISOLanguageCodeParameter}${endpointPath}`;
        return axiosInstance.get(completePath);
    }

    static postApiData(endpointPath, data) {
        const completePath = `${RSB_SERVICE}${endpointPath}`;
        return axiosInstance.post(completePath, data);
    }

    static postApiDataWithConfig(endpointPath, data, config) {
        const completePath = `${RSB_SERVICE}${endpointPath}`;
        return axiosInstance.post(completePath, data, config);
    }

    // Ova funkcija se koristi za slanje POST zahtjeva u responsiveFrontendBundle.
    static postUserData(endpointPath, data) {
        const mainURL = window.location.origin;
        const completePath = `${mainURL}/${this.twoLetterISOLanguageCodeParameter}/${endpointPath}`;
        return axiosInstance.post(completePath, data);
    }

    static postApiDataWithLangISO(endpointPath, data) {
        const completePath = `${RSB_SERVICE}${endpointPath}${this.twoLetterISOLanguageCodeParameter}`;
        return axiosInstance.post(completePath, data);
    }

    static getDataOutOfRSB(endpointPath) {
        const completePath = `${BASE_URL}${this.twoLetterISOLanguageCodeParameter}${endpointPath}`;
        return axiosInstance.get(completePath);
    }
}

serviceData.setupInterceptors();
