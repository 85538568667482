import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import userData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/userData';
import axios from 'axios';

const userMenuBtn = document.getElementById('btnUserMenu');
const userMenu = document.getElementById('userMenu');
const formInputs = document.querySelectorAll('.form__input');
const emailInput = document.getElementById('userMenuLoginEmail');
const emailFormMessage = emailInput?.parentElement?.nextElementSibling;
const emailClearButton = document.getElementById('userMenuLoginEmailClearButton');
const loginForm = document.getElementById('userMenuLoginForm');
const passwordInput = document.getElementById('userMenuLoginPassword');
const passwordPeekButton = document.getElementById('userMenuLoginPasswordPeekButton');
const showPasswordIcon = document.getElementById('userMenuLoginShowPasswordIcon');
const hidePasswordIcon = document.getElementById('userMenuLoginHidePasswordIcon');
const submitButton = document.getElementById('userMenuLoginSubmitButton');
const modalBody = document.getElementById('userMenuModalInnerBody');
const baseURL = window.location.origin;
const twoLetterISOLanguageCodeParameter = document.documentElement.dataset.adriaticlang;
const loginPath = `${baseURL}/${twoLetterISOLanguageCodeParameter}/perform-login`;
const csrfToken = document.getElementById('userMenuLoginCsrfToken')?.value;
const logoutButton = document.getElementById('logoutLink');
let isFormSubmittedOnce = false;

if (userMenuBtn && userMenu) {
    userMenu.addEventListener('hidden.bs.modal', () => {
        userMenuBtn.classList.remove('btn-active');
    });
}

const handleInputState = (inputElement) => {
    const inputWrapper = inputElement?.parentNode;
    if (!inputWrapper) return;
    const isInputEmpty = inputElement === passwordInput ? inputElement.value === '' : inputElement.value.trim() === '';
    inputWrapper.classList.toggle('form__input--wrapper-filled', !isInputEmpty);
    if (isFormSubmittedOnce) {
        inputWrapper.classList.toggle('form__input--wrapper-error', isInputEmpty);
        const formMessage = inputWrapper.nextElementSibling;
        if (formMessage) {
            formMessage.textContent = formMessage.dataset.requiredError;
            formMessage.classList.toggle('form__message--visible', isInputEmpty);
        }
    }
    if (emailClearButton) {
        emailClearButton.style.display = isInputEmpty ? 'none' : null;
    }
};

formInputs?.forEach((inputElement) => {
    inputElement.addEventListener('input', () => {
        handleInputState(inputElement);
    });
});

emailClearButton?.addEventListener('click', () => {
    if (!emailInput) return;
    emailInput.value = '';
    handleInputState(emailInput);
});

passwordPeekButton?.addEventListener('click', () => {
    if (!passwordInput || !showPasswordIcon || !hidePasswordIcon) return;
    const shouldShowPassword = passwordInput.type === 'password';
    passwordInput.type = shouldShowPassword ? 'text' : 'password';
    showPasswordIcon.style.display = shouldShowPassword ? 'none' : null;
    hidePasswordIcon.style.display = shouldShowPassword ? null : 'none';
});

loginForm?.addEventListener('submit', (e) => {
    e.preventDefault();
    if (!emailInput || !passwordInput) return;
    isFormSubmittedOnce = true;
    const email = emailInput.value;
    const password = passwordInput.value;
    const isEmailEmpty = email.trim() === '';
    const isPasswordEmpty = password === '';
    if (isEmailEmpty || isPasswordEmpty) {
        handleInputState(emailInput);
        handleInputState(passwordInput);
        if (isEmailEmpty) {
            emailInput.focus();
        } else {
            passwordInput.focus();
        }
        return;
    }
    if (submitButton) {
        submitButton.disabled = true;
    }
    modalBody?.classList.add('disabledElements');
    axios.post(loginPath, {
        email,
        password,
        csrf_token: csrfToken,
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res.status === 200) {
                if (res.data.status === 'failure' || res.data.validationErrorMessages) {
                    if (!emailFormMessage) return;
                    emailFormMessage.classList.add('form__message--visible');
                    emailFormMessage.textContent = emailFormMessage.dataset.credentialsError;
                    const inputWrapper = emailFormMessage.previousElementSibling;
                    inputWrapper?.classList.add('form__input--wrapper-error');
                    emailInput?.focus();
                    return;
                }
                window.location.reload();
                return;
            }
            reportService.reportError(res);
        })
        .catch((err) => {
            reportService.reportError(err);
        })
        .finally(() => {
            if (submitButton) {
                submitButton.disabled = false;
            }
            modalBody?.classList.remove('disabledElements');
        });
});

logoutButton?.addEventListener('click', () => userData.removeTokenFromLocalStorage());
