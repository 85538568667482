import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class reportService {
    static handleAxiosError(error) {
        const axiosCustomErrors = JSON.parse(document.getElementById('mainWrapper')?.getAttribute('data-axios'));
        if (axios.isAxiosError(error)) {
            switch (error.code) {
                case 'ERR_NETWORK':
                    toast.error(axiosCustomErrors?.connectionError);
                    break;
                case 'ERR_BAD_REQUEST':
                    if (error.config?.method === 'post' || error.config?.method === 'options') {
                        toast.info(axiosCustomErrors?.unavailableText);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    static reportError(errorData) {
        // TODO: Potrebno odrediti koje sve informacije želimo slati kad dođe do error-a.
        if (axios.isCancel(errorData)) return;
        this.handleAxiosError(errorData);
        const postPath = 'log/error';
        const browserInfo = {
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            platform: window.navigator.platform,
            cookiesEnabled: window.navigator.cookieEnabled,
            online: window.navigator.onLine,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            colorDepth: window.screen.colorDepth,
        };
        function isBot(userAgent) {
            const botPatterns = [
                /Googlebot.*www\.google\.com\/bot\.html/,
                /bingbot.*www\.bing\.com\/bingbot\.htm/,
                /YandexMobileBot\/3\.0.*yandex\.com\/bots/,
                /AdsBot-Google-Mobile.*www\.google\.com\/mobile\/adsbot\.html/,
                /Google-Read-Aloud.*support\.google\.com\/webmasters\/answer\/1061943/,
                /AdsBot-Google.*www\.google\.com\/adsbot\.html/,
                /YandexBot\/3\.0.*yandex\.com\/bots/,
                /Baiduspider-render\/2\.0.*www\.baidu\.com\/search\/spider\.html/,
                /Applebot\/0\.1;.*http:\/\/www\.apple\.com\/go\/applebot/,
                /meta-externalagent\/1\.1.*developers\.facebook\.com\/docs\/sharing\/webmasters\/crawler/,
            ];
            return botPatterns.some((pattern) => pattern.test(userAgent));
        }
        if (isBot(browserInfo.userAgent)) {
            return;
        }
        const errorInfo = {
            message: errorData.message || 'Nepoznato',
            stack: errorData.stack || 'Nepoznato',
            name: errorData.name || 'Nepoznato',
            fileName: errorData.fileName || 'Nepoznato',
            lineNumber: errorData.lineNumber || 'Nepoznato',
            columnNumber: errorData.columnNumber || 'Nepoznato',
            cause: errorData.cause || 'Nepoznato',
            code: errorData.code || 'Nepoznato',
            details: errorData.details || 'Nepoznato',
            timestamp: new Date().toLocaleString('en-GB', { timeZone: 'Europe/Zagreb' }),
        };
        const preparedData = {
            errorName: errorData.code || errorData.name || 'ERROR NAME NIJE ZABILJEŽEN',
            errorOnPage: window.location.href,
            buildName: typeof ADRIATIC_BUILD_RELEASE_NAME !== 'undefined' ? ADRIATIC_BUILD_RELEASE_NAME : 'Nepoznato',
            buildTime: typeof ADRIATIC_BUILD_TIME !== 'undefined' ? ADRIATIC_BUILD_TIME : 'Nepoznato',
            errorInfo,
            errorData,
            browserInfo,
        };
        const completedData = {
            logText: JSON.stringify(preparedData, null, 4),
        };
        serviceData
            .postApiData(postPath, completedData)
            .then(() => {
                // TODO: Dok smo na developmentu
            })
            .catch(() => {
            });
    }

    static reportErrorSSR(errorData) {
        const postPath = 'log/error';
        const errorInfo = {
            message: errorData?.message || 'Nepoznato',
            stack: errorData?.stack || 'Nepoznato',
            name: errorData?.name || 'Nepoznato',
            fileName: errorData?.fileName || 'Nepoznato',
            lineNumber: errorData?.lineNumber || 'Nepoznato',
            columnNumber: errorData?.columnNumber || 'Nepoznato',
            cause: errorData?.cause || 'Nepoznato',
            code: errorData?.code || 'Nepoznato',
            details: errorData?.details || 'Nepoznato',
            timestamp: new Date().toLocaleString('en-GB', { timeZone: 'Europe/Zagreb' }),
        };
        const preparedData = {
            errorName: errorData?.code || errorData?.name || 'ERROR NAME NIJE ZABILJEŽEN',
            errorOnPage: 'SSR ERROR',
            customMessage: errorData?.customMessage || 'Nepoznato',
            buildName: typeof ADRIATIC_BUILD_RELEASE_NAME !== 'undefined' ? ADRIATIC_BUILD_RELEASE_NAME : 'Nepoznato',
            buildTime: typeof ADRIATIC_BUILD_TIME !== 'undefined' ? ADRIATIC_BUILD_TIME : 'Nepoznato',
            errorInfo,
            errorData: JSON.stringify(errorData),
        };
        const completedData = {
            logText: JSON.stringify(preparedData, null, 4),
        };
        serviceData.postApiData(postPath, completedData);
    }
}
